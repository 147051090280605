import Link from "next/link";

export default function PostFooter({ footer, targetAudience }) {
  return (
    <div className={`post-footer pt-10 pb-10 ${targetAudience === 'business' ? 'post-footer--blue' : ''}`}>
      <div className="container">
        <div className="post-footer__inner">
          <div className="flex">
            {footer.post_footer.menu_list &&
              footer.post_footer.menu_list.map((item) => {
                item = item.link;
                return (
                  <Link href={item.url} target={item.target} key={item.title}>
                    <a>{item.title}</a>
                  </Link>
                );
              })}
          </div>
          {footer.post_footer.text && (
            <div className="flex flex--wrap flex--justify-center text-center pt-10 pt-sm-0">
              <span>{footer.post_footer.text}</span>
              <a
                className="ml-20 pt-10 pt-sm-0"
                href="https://www.ferre.dev"
                target="_blank"
              >
                Website door Ferre
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
