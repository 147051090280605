import Link from "next/link";
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import NavbarTopbar from "./navbar-topbar";
import {countryMySamSamLink} from "../lib/countryConfig";

export default function NavbarDesktop({menus, header, targetAudience}) {
    const router = useRouter();

    const [isHidden, setIsHidden] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);

    let previousScroll = 0;

    const controlNavbar = () => {
        let currentScroll = window.scrollY;

        if (window.scrollY < 250) {
            setIsHidden(false);
        } else {
            if (currentScroll > 0) {
                if (window.scrollY >= previousScroll) {
                    setIsHidden(true);
                } else {
                    setIsHidden(false);
                }
            }
        }

        previousScroll = window.scrollY;
    };

    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, []);

    return (
        <div
            className={`navbar-desktop ${isHidden && "navbar-desktop--is-hidden"}`}
        >
            <NavbarTopbar header={header} targetAudience={targetAudience}/>
            <div className="position--relative">
                <span className="navbar-desktop__logo-shadow"/>
                <div className="navbar-desktop__bar">
                    <ul className="tw-flex-wrap tw-w-[45%]">
                        {menus["main-menu-left"].items.map((item) => {
                            if (item.url === "#" && item.subItems) {
                                return (
                                    <li className="tw-relative"
                                        key={item.url}
                                        onMouseEnter={() => setShowSubMenu(true)}
                                        onMouseLeave={() => setShowSubMenu(false)}>
                                        <a href="#" className="hover:!tw-text-black">
                                            <span>{item.title}</span>
                                            <span className={`tw-ml-2 tw-transition ${showSubMenu ? "tw-rotate-180" : ""}`}>
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                        <div
                                            className={`${showSubMenu ? "tw-absolute tw-bottom-0 tw-translate-y-full -tw-translate-x-1/2 tw-left-1/2 tw-pt-20" : "!tw-hidden"}`}>
                                            <div
                                                className="tw-flex tw-flex-wrap tw-w-[350px] tw-bg-white tw-gap-y-10 tw-shadow-xl tw-py-12 tw-px-4 tw-rounded-[30px]">
                                                {item.subItems.map((subItem) => {
                                                    return (
                                                        <div className="tw-w-1/3">
                                                            <Link href={subItem.url} target={subItem.target}>
                                                                <a className="tw-group !tw-flex tw-flex-col tw-justify-center tw-text-center tw-items-center tw-gap-y-3 tw-text-[12px] !tw-p-0 hover:!tw-text-black">
                                                                        <span
                                                                            className="tw-bg-gray-100 tw-w-[70px] tw-h-[70px] tw-rounded-2xl tw-grow-0 group-hover:tw-bg-gray-200 tw-transition tw-p-4">
                                                                            <img
                                                                                src={subItem.iconSrc}
                                                                                alt={subItem.title}
                                                                                className="tw-object-contain tw-w-full tw-h-full"/>
                                                                        </span>
                                                                    <span>{subItem.title}</span>
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={item.url}>
                                        <Link href={item.url} target={item.target}>
                                            <a className={router.asPath === item.url ? "current" : ""}>
                                                {item.title}
                                            </a>
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                    <div className="navbar-desktop__logo">
                        <Link href="/">
                            <a onMouseEnter={() => setIsHidden(false)}/>
                        </Link>
                    </div>
                    <ul>
                        {menus["main-menu-right"].items.map((item) => {
                            return (
                                <li key={item.url}>
                                    <Link href={item.url} target={item.target}>
                                        <a
                                            className={router.asPath === item.url ? "current" : ""}
                                        >
                                            {item.title}
                                        </a>
                                    </Link>
                                </li>
                            );
                        })}
                        {countryMySamSamLink.url &&
                            <li>
                                <Link href={countryMySamSamLink.url}>
                                    <a>
                                        <i className="fas fa-user-circle"/> {countryMySamSamLink.title}
                                    </a>
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
        ;
}
